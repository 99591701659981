import React from 'react'
import styled from '@emotion/styled'
import {typography, margin, colors, padding} from 'styles'
import {trackOutboundLink} from 'analytics'
import useSiteMetadata from 'hooks/useSiteMetadata'

const Grid = styled.ol({
  listStyle: 'none',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  justifyContent: 'center',
  ...margin.bottomBlade(),
})

const Item = styled.li({
  ...padding(24),
  flexGrow: 1,
  flexBasis: 320,
  maxWidth: 400,
})

const Title = styled.p({
  ...typography.headline4,
  ...margin.bottom(8),
  textAlign: 'center',
})

const Text = styled.p({
  ...typography.body,
  ...margin.bottom(16),
  textAlign: 'center',
})

const Subtext = styled.p({
  ...typography.body,
  textAlign: 'center',
  fontStyle: 'italic',
})

const StyledA = styled.a({
  color: colors.primary[60],
})

const AdditionalInfo = () => {
  const {appUrls} = useSiteMetadata()
  return (
    <Grid>
      <Item>
        <Title>Who counts as a member?</Title>
        <Text>
          A member is any Trello user added to an active Trello board where
          Hello Epics is enabled. Members who are on multiple boards are only
          counted once.
        </Text>
      </Item>
      <Item>
        <Title>Fair billing policy</Title>
        <Text>
          At Hello Epics, we believe you should only pay for members on the
          boards you're using. Pay for a member once, no matter how many boards
          they're on. If a board becomes inactive, we'll credit your account for
          any unused time.
        </Text>
        <Subtext>
          Find more information on our{' '}
          <StyledA
            href={appUrls.billingSupportUrl}
            onClick={() =>
              trackOutboundLink('Billing Support', appUrls.billingSupportUrl)
            }
          >
            billing&nbsp;policy
          </StyledA>
          {'.'}
        </Subtext>
      </Item>
    </Grid>
  )
}

export default AdditionalInfo
