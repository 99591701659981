import React from 'react'

import Header from 'components/PricingPage/Header'
import Prices from 'components/PricingPage/Prices'
import AdditionalInfo from 'components/PricingPage/AdditionalInfo'
import SiteMetadata from 'components/SiteMetadata'

const PricingPage = () => (
  <>
    <SiteMetadata
      title="Pricing"
      metaDescription="$5.99 per member a month. Save 33% with annual billing. Unlimited boards + epics."
    />
    <Header />
    <Prices />
    <AdditionalInfo />
  </>
)

export default PricingPage
