import React from 'react'
import Wrapper from 'components/Wrapper'
import styled from '@emotion/styled'
import {colors, margin, padding, breakpoint, typography} from 'styles'
import spacing from 'styles/constants/spacing'
import AddToTrelloButton from 'components/AddToTrelloButton'
import {trackOutboundLink} from 'analytics'
import useSiteMetadata from 'hooks/useSiteMetadata'

const ScreenReaderOnly = styled.span({
  position: 'absolute',
  left: -10000,
  top: 'auto',
  width: 1,
  height: 1,
  overflow: 'hidden',
})

const Block = styled.div({
  ...padding.x(24),
  ...padding.y(36),
  backgroundColor: colors.primary[0],
  borderRadius: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [breakpoint.mediumAndUp]: {
    ...padding.x(36),
  },
})

const PricingOptions = styled.div({
  ...margin.bottom(36),
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridRowGap: spacing(36),
  gridColumnGap: spacing(64),
  [breakpoint.mediumAndUp]: {
    gridTemplateColumns: '1fr 1fr',
  },
})

const PricingOption = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const Title = styled.h2({
  ...typography.headline3,
})

const EmptyDeal = styled.div({
  display: 'none',
  [breakpoint.mediumAndUp]: {
    display: 'block',
    height: spacing(16),
  },
})

const Deal = styled.p({
  ...typography.caption,
})

const PriceTag = styled.div({
  ...margin.top(8),
  ...padding(16),
  alignSelf: 'stretch',
  backgroundColor: 'white',
  borderRadius: 32,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'center',
})

const Cost = styled.p({
  ...typography.display,
  ...margin.top(-4),
  lineHeight: '2.5rem', // fixes empty space below
  '@supports (-moz-appearance:none)': {
    /* Firefox-only: fixes the tags being off-center */
    /* caused by something being wrong in the primary font */
    ...margin.top(-2 as any),
  },
  em: {
    ...typography.headline4,
    fontStyle: 'normal',
    verticalAlign: '1.4rem',
  },
})

const Terms = styled.p({
  ...margin.left(8),
  ...typography.caption,
  fontStyle: 'italic',
})

const Bullets = styled.ul({
  ...margin.top(24),
  ...margin.bottom(36),
  display: 'inline-block',
  textAlign: 'center',
  listStyleType: 'none',
})

const Bullet = styled.li({
  ...typography.body,
  fontStyle: 'italic',
  '&:not(:last-child)': {
    ...margin.bottom(16),
  },
  [breakpoint.smallAndUp]: {
    '&:not(:last-child)': {
      ...margin.bottom(0),
    },
  },
})

const EnterpriseUpsell = styled.div({})

const Question = styled.p({
  ...margin.bottom(8),
  ...typography.headline4,
  textAlign: 'center',
  [breakpoint.mediumAndUp]: {
    ...margin.bottom(0),
    display: 'inline',
  },
})

const Answer = styled.p({
  ...typography.headline4,
  textAlign: 'center',
  [breakpoint.mediumAndUp]: {
    ...margin.left(4),
    display: 'inline',
  },
})

const Prices = () => {
  const {appUrls} = useSiteMetadata()

  return (
    <Wrapper
      css={{display: 'flex', justifyContent: 'center', ...margin.bottom(48)}}
    >
      <Block>
        <PricingOptions>
          <PricingOption>
            <EmptyDeal />
            <Title>Monthly Billing</Title>
            <PriceTag>
              <Cost>
                <span aria-hidden>
                  <em>$</em>5<em>99</em>
                </span>
                <ScreenReaderOnly>$5.99</ScreenReaderOnly>
              </Cost>
              <Terms>
                member / month
                <br />
                billed monthly
              </Terms>
            </PriceTag>
          </PricingOption>
          <PricingOption>
            <Deal>Save 33%</Deal>
            <Title>Annual Billing</Title>
            <PriceTag>
              <Cost>
                <span aria-hidden>
                  <em>$</em>3<em>99</em>
                </span>
                <ScreenReaderOnly>$3.99</ScreenReaderOnly>
              </Cost>
              <Terms>
                member / month
                <br />
                $47.88 billed annually
              </Terms>
            </PriceTag>
          </PricingOption>
        </PricingOptions>
        <AddToTrelloButton large />
        <Bullets>
          <Bullet>Use Hello Epics on unlimited&nbsp;boards</Bullet>
          <Bullet>Create unlimited parent‑child&nbsp;relationships</Bullet>
        </Bullets>
        <EnterpriseUpsell>
          <Question>
            Interested in Hello Epics for your&nbsp;Enterprise?
          </Question>
          <Answer>
            <a
              css={{color: colors.primary[60]}}
              href={appUrls.enterpriseContactUrl}
              onClick={() =>
                trackOutboundLink(
                  'EnterpriseContactMailto',
                  appUrls.enterpriseContactUrl
                )
              }
            >
              Contact us for plans&nbsp;and&nbsp;pricing
            </a>
          </Answer>
        </EnterpriseUpsell>
      </Block>
    </Wrapper>
  )
}

export default Prices
